import { $api } from "../AxiosInstance";

export class UserService {
  // Hidden because getAllUsers has been removed
  // static getAllUsers() {
  //   return $api.get("/api/admin/user/all");
  // }

  static getOverallBalance() {
    return $api.get("/api/admin/balance/overall");
  }

  static getUsers(params) {
    return $api.post("/api/admin/user/all", params);
  }

  static getUsersWithBalance(params) {
    return $api.post("/api/admin/user/all/withBalance", params);
  }

  static getAdminUser() {
    return $api.get("/api/admin/user/adminUser");
  }

  static createDefaultUser(email, password) {
    return $api.post("/api/admin/user/createDefaultUser", { email, password });
  }

  static getUserInfo() {
    return $api.get("/api/admin/user/profile");
  }

  static async login(username, password) {
    try {
      const response = await $api.post("/api/login", null, {
        params: { username, password }
      });

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("Login error: ", e);
      return false;
    }
  }

  static logout() {
    return $api.get("/api/logout");
  }

  static getUsersByRole(role) {
    return $api.get("/api/admin/user/all/byRole", { params: { role } });
  }

  static getUserDataById(id) {
    return $api.get("/api/admin/user", { params: { id } });
  }

  static getUserIps(params) {
    return $api.post("/api/admin/ipAddress/all/userId", params);
  }
  static getCountActiveIpsOnMobileOperator(mobileOperatorId) {
    return $api.get("/api/admin/ipAddress/check/active/by/mobileOperatorId", {
      params: { mobileOperatorId }
    });
  }

  static setUserPassword(params) {
    return $api.post("/api/admin/user/password/userId", params);
  }

  static getBalanceHitory(params) {
    return $api.post("/api/admin/balanceHistory/all/balanceId", params);
  }

  static changeUserBalance(params) {
    return $api.post("/api/admin/balance/correct", params);
  }
}
