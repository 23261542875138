import { $api } from "services/AxiosInstance";

export class DiscountService {
  static getAllDiscounts() {
    return $api.get("/api/admin/discount/all");
  }

  static getDiscounts(page, size) {
    return $api.post("/api/admin/priceCorrection/all/type", { page, size });
  }

  // static getDiscountsByProxy() {
  //   return $api.get("/api/admin/discount/all/groupByProxy");
  // }

  static getDiscount(id) {
    return $api.get("/api/admin/priceCorrection", { params: { id } });
  }

  static createDiscount(values) {
    return $api.post("/api/admin/priceCorrection", values);
  }

  static updateDiscount(values) {
    return $api.put("/api/admin/priceCorrection", values);
  }

  static deleteDiscount(id) {
    return $api.delete("/api/admin/priceCorrection", { params: { id } });
  }
}
